<template>
  <div>
    <div class="zone-card">
      <zone-card :title="company.companyId != '2114' ? '直采商城' : '集采商城' "></zone-card>
      <img class="w-100" style="cursor: pointer" @click="$utils.goCollectiveShop(company)" src="@/assets/image/pc/shop-banner.png" alt="">
      <div>
        <div class="shop-title">
          <img src="@/assets/image/pc/hot.png">&nbsp;热销商品
        </div>
        <div class="shop">
          <div class="shop-item" @click="$utils.goCollectiveShop(company)"  v-for="(item,index) in shopList" v-show="(isMobile && index < 6) || (!isMobile)"
               :key="index+'agsafaj'">
            <img class="w-100" :src="require('../../assets/image/pc/shop/'+item)">
          </div>
        </div>
        <div class="classify-button classify-button-collective">
          <el-divider content-position="center">
            <div @click="$utils.goCollectiveShop(company)"  class="classify-more" @mouseover="changeIndex(0,'collectiveShopMoreList')"
                 @mouseout="changeIndexBack(0,'collectiveShopMoreList')" v-for="(item,inedx) in collectiveShopMoreList"
                 :key="inedx+'scas'">
            <img class="w-100" v-show="!item.show" :src="require('../../assets/image/pc/'+item.unchecked+'.png')"
                   alt="">
              <img class="w-100" v-show="item.show" :src="require('../../assets/image/pc/'+item.active+'.png')" alt="">
            </div>
          </el-divider>
        </div>
      </div>
    </div>
    <div class="zone-card">
      <zone-card title="员工易购"></zone-card>
      <div class="shop">
        <div class="products-banner">
          <img class="w-100 h-100"  @click="$utils.goWelfareShop(company)" src="@/assets/image/pc/shop/福利商城-左banner图.png" alt="">
        </div>
        <div class="products">
          <div class="welfare-shop-item"  @click="$utils.goWelfareShop(company)" v-for="(item,index) in welfareShopList" :key="index+'dawda'">
            <img class="w-100" v-show="!item.show" :src="require('../../assets/image/pc/shop/'+item)">
          </div>
        </div>
      </div>
      <div class="classify-button">
        <el-divider content-position="center">
          <div class="classify-more"  @click="$utils.goWelfareShop(company)"  @mouseover="changeIndex(0,'welfareShopMoreList')"
               @mouseout="changeIndexBack(0,'welfareShopMoreList')" v-for="(item,index) in welfareShopMoreList" :key="index+'qrfa'">
            <img class="w-100" v-show="!item.show" :src="require('../../assets/image/pc/'+item.unchecked+'.png')"
                 alt="">
            <img class="w-100" v-show="item.show" :src="require('../../assets/image/pc/'+item.active+'.png')" alt="">
          </div>
        </el-divider>
      </div>
    </div>
  </div>
</template>

<script>
import ZoneCard from "./zone-card";

export default {
  name: "centralized-shop",
  components: {ZoneCard},
  props:["company"],
  data() {
    return {
      isMobile: false,
      shopList: [
        '卷纸.png',
        '头盔.png',
        '打印机.png',
        '打印纸.png',
        '排插.png',
        '探测仪.png',
        '探照灯.png',
        '气动扳手.png',
        '电脑.png',
        '螺丝.png'],
      welfareShopList: [
        '大米.png',
        '和牛.png',
        '咖啡机.png',
        '烧烤箱.png',
        '椰子.png',
        '羽毛球.png'],
      collectiveShopMoreList: [
        {
          unchecked: 'collective-view-more',
          active: 'collective-view-more-active',
          show: false
        }
      ],
      welfareShopMoreList: [
        {
          unchecked: 'welfare-view-more',
          active: 'welfare-view-more-active',
          show: false
        }
      ],
    }
  },
  created() {
    let that = this;
    that.pageResize();
    window.addEventListener('resize', () => {
      that.pageResize();
      //屏幕大小变化的方法
    }, true);
  },
  methods: {
    pageResize() {
      let width = document.documentElement.clientWidth;
      if (width < 768) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    changeIndex(e, entryName) {
      this[entryName][e].show = !this[entryName][e].show;
    },
    changeIndexBack(e, entryName) {
      this[entryName][e].show = !this[entryName][e].show;
    },

  }
}
</script>

<style scoped lang="scss">
.shop-title {
  height: 26px;
  font-size: 2.6rem;
  margin: 20px 0px;

  img {
    width: 43px;
    height: 29px;
  }
}

.shop {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2%;

  .shop-item {
    cursor: pointer;
    width: 19%;
    margin-bottom: 10px;
  }

}

.classify-button {
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
}

.classify-button-collective {
  margin-top: 20px;

}

.classify-more {
  width: 180px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 0px;
  background: white;
}

.el-divider {
  background: #5387F7;
}

.el-divider__text {
  padding: 0 8 s0px;
}

.products {
  width: 63%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  gap: 1%;

  .welfare-shop-item {
    width: 32.6%;
    cursor: pointer;
  }
}

.products-banner {
  cursor: pointer;
  float: right;
  width: 35%;
}

@media screen and (max-width: 576px) {
  .classify-more {
    width: 150px;
    margin-top: 0px;
  }


  .shop {

    .shop-item {
      width: 32.5% !important;
      margin-bottom: 10px;
    }

    .products-banner {
      display: none;
    }

    .products {
      width: 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .classify-more {
    width: 150px;
    margin-top: 0px;
  }

  .shop {
    .shop-item {
      width: 32.5% !important;
      margin-bottom: 10px;
    }

    .products-banner {
      display: none;
    }

    .products {
      width: 100%;
    }
  }


}


@media (min-width: 768px) and (max-width: 992px) {
  .classify-more {
    width: 180px;
    margin-top: 10px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .classify-more {
    width: 200px;
    margin-top: 0px;
  }
}

@media (min-width: 1200px) {
  .classify-more {
    width: 180px;
    margin-top: 0px;
  }
}
</style>
