<template>
  <div class="zone-two">
    <operate-right></operate-right>
    <zone-new-head-two :company="company"></zone-new-head-two>
    <div class="zone-carousel">
      <div id="navigation-carousel">
        <el-carousel height="100%">
          <el-carousel-item v-for="item in carousels" v-bind:key="item.id">
            <a v-for="(imgItem) in item.bgUrl" v-bind:key="imgItem.id" class="cms-ssm-hidden cms-sm-hidden">
              <div
                  v-bind:style="{'background-image': 'url(' + imgItem.url + ')','background-repeat':'no-repeat','background-size':'cover','width':'100%','height':'100%','background-position': 'center'}">
              </div>
            </a>
            <a class="cms-lg-hidden cms-md-hidden cms-xl-hidden" v-for="(imgItem) in item.imagesUrl" v-bind:key="imgItem.id">
              <div
                  v-bind:style="{'background-image': 'url(' + imgItem.url + ')','background-repeat':'no-repeat','background-size':'cover','width':'100%','height':'100%','background-position': 'center'}">
              </div>
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="zone-content">
      <purchase-info :company="company"></purchase-info>
      <specialized-new-zone-advertise style="margin-top: 10px;width: 100%"></specialized-new-zone-advertise>
      <centralized-shop v-show="company.companyId !='6802'" :company="company"></centralized-shop>
      <zone-solution-two v-show="company.companyId !='6802'"></zone-solution-two>
    </div>
    <zone-footer-two :company="company"></zone-footer-two>
  </div>
</template>

<script>
import axios from "axios";
import ZoneNewHeadTwo from "./zone-new-head-two";
import PurchaseInfo from "./purchase-info";
import SpecializedNewZoneAdvertise from "../specialized-new-zone/specialized-new-zone-advertise";
import CentralizedShop from "./centralized-shop";
import ZoneSolutionTwo from "./zone-solution-two";
import ZoneFooterTwo from "./zone-footer-two";
import OperateRight from "./operate";

export default {
  name: "index",
  components: {
    OperateRight,
    ZoneFooterTwo,
    ZoneSolutionTwo, CentralizedShop, SpecializedNewZoneAdvertise, PurchaseInfo, ZoneNewHeadTwo
  },
  data() {
    return {
      carouselList: [],
      carousels: [],
      carousel: {},
      company: {},
    }
  },
  methods: {
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].companyId == that.company.companyId) {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
              that.carousels.push(data.data.result[i]);
            }
          }
          that[entityListName] = data.data.result;
        })
      })
    },
    getCompany: function (promotionId, entityName) {
      var that = this;
      that.promotion = promotionId;
      that.$http.get(that.base + "/promotion/get.do", {
        params: {
          id: that.promotion
        }
      }).then((da) => {
        if (da.data.result.title.indexOf('涟源') != -1 || da.data.result.title.indexOf('涟钢') != -1 || da.data.result.title.indexOf('VAMA') != -1 || da.data.result.title.indexOf('安赛乐米塔尔') != -1) {
          that.isBid = true;
          that.activeClass = 1;
        }
        that[entityName] = da.data.result;
        that.$nextTick(() => {
          document.title = that.company.title;
        })
      });
    },
  },
  created() {
    this.getCompany(this.$route.query.companyId, "company");
    this.selectPromotionList("zone_carousel", "carousel", "carouselList");
  }
}
</script>

<style scoped>

.zone-content {
  width: 1160px;
  margin: 0 auto;
  padding-top: 10px;
  background: white;
  overflow: auto;
}

#navigation-carousel {
  width: 100%;
  height: 248px;
  background: #EDEDED;
}

@media screen and (max-width: 576px) {
  #navigation-carousel {
    height: 200px;
    background: #EDEDED;
  }

  .el-carousel__button {
    width: 3px !important;
    height: 3px !important;
  }

  #navigation-carousel .el-carousel {
    height: 200px !important;
  }

  .zone-content {
    width: 100%;
    padding: 0px 20px;
  }
  .zone-two{
    margin-top: 110px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #navigation-carousel {
    background: #EDEDED;
    height: 230px !important;
    margin: 0px 0px 0px 0px;
  }


  #navigation-carousel .el-carousel {
    height: 230px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .zone-content {
    width: 100%;
    padding: 10px 20px;
  }
  .zone-two{
    margin-top: 110px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #navigation-carousel {
    height: 300px !important;
    background: #EDEDED;
  }

  .zone-two{
    margin-top: 60px;
  }
  #navigation-carousel .el-carousel {
    height: 300px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .zone-content {
    width: 100%;
    padding: 10px 20px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {

  .zone-two{
    margin-top: 75px;
  }
  #navigation-carousel {
    height: 400px !important;
    background: #EDEDED;
  }

  #navigation-carousel .el-carousel {
    height: 400px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

  .zone-content {
    width: 100%;
    padding: 0px 20px;
    background: white;
  }
}

@media (min-width: 1200px) {
  #navigation-carousel {
    height: 500px !important;
    background: #EDEDED;
  }

  .zone-two{
    margin-top: 75px;
  }
  #navigation-carousel .el-carousel {
    height: 500px !important;
  }

  .el-carousel__item img {
    color: #475669;
    font-size: 1.4rem;
    opacity: 0.75;
    height: 100% !important;
    margin: 0;
  }

}

</style>