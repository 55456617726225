<template>
  <div>
    <div class="cms-header-content left">
      <div class="cms-container cms-head">
        <div class="logo-image left">
          <img :src="logoUrl" class="w-100 h-100" alt="">
        </div>
        <ul style="width: 120px" class="menu_ul right cms-lg-hidden cms-xl-hidden cms-md-hidden  login">
          <li class="right categoryItem" @click="login">
            <span class="one_a" style="cursor: pointer" target="_blank" href="javascript:void(0)">登录/注册</span>
          </li>
        </ul>
        <div class="head-right">
          <ul class="menu_ul right clearfix menu_ul_new">
            <li class="right categoryItem">
              <router-link
                  :class="{'one_a':true,'qy_one_a':company.companyId=='2114','head-active':headActive('specializedNewZoneTwo'),'qy-head-active':headActive('specializedNewZoneTwo') && company.companyId=='2114' }"
                  :to="{path:'/specializedNewZoneTwo','query':{'companyId':headCompanyId,'id':company.companyId,'headLogo':headLogo}}">
                首页
              </router-link>
            </li>
            <li class="right categoryItem">
              <router-link :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}"
                           v-if="company.companyId != '2706'"
                           :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':company.companyId,'headLogo':headLogo}}">
                招标采购
              </router-link>
              <router-link :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" to="/purchase"
                           v-if="company.companyId == '2706'">招标采购
              </router-link>
            </li>
            <li class="right categoryItem" v-if="company.companyId !='2114' && company.companyId !='6802'">
              <a :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" target="_blank"
                 href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm">电子卖场</a>
            </li>
            <li class="right categoryItem" @click="$utils.goCollectiveShop(company)" v-if="company.companyId !='6802'">
              <span :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" style="cursor: pointer"
                    href="javascript:void(0)"> {{ company.companyId != '2114' ? '直采商城' : '集采商城' }} </span>
            </li>
            <li class="right categoryItem" @click="$utils.goWelfareShop(company)" v-if="company.companyId !='6802'">
              <span :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" target="_blank"
                    href="javascript:void(0)">员工易购</span>
            </li>
            <li class="right categoryItem" @click="toLink(1)" v-if="company.companyId =='6802'">
              <span :class="{'one_a':true}">公司官网</span>
            </li>
            <li class="right categoryItem" @click="toLink(2)" v-if="company.companyId =='6802'">
              <span :class="{'one_a':true}">政策法规</span>
            </li>
<!--            <li class="right categoryItem" @click="$utils.goWelfareShop(company)" v-if="company.companyId !='6802'">-->
<!--              <span :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" target="_blank"-->
<!--                    href="javascript:void(0)">员工易购</span>-->
<!--            </li>-->
            <li class="right categoryItem" v-if="company.companyId =='2114'">
              <a :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" target="_blank"
                 href="https://client.holdsteel.com/marketpt/bidsaloon/items/index.htm">电子卖场</a>
            </li>
            <li class="right categoryItem">
              <router-link :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" to="/help">帮助中心</router-link>
            </li>
          </ul>
          <ul class="menu_ul right cms-sm-hidden cms-ssm-hidden  menu_ul_new login">
            <li class="right categoryItem" @click="login">
              <span :class="{'one_a':true,'qy_one_a':company.companyId=='2114'}" target="_blank"
                    href="javascript:void(0)">登录/注册</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "specialized-new-zone",
  created() {
    this.headActive();
    this.headCompanyId = this.$route.query.companyId;
    this.headId = this.$route.query.id;
    this.headLogo = this.$route.query.headLogo;
    this.id = this.$route.query.id;
    if (this.companyId == null || this.companyId == "") {
      this.isCompany = false;
    }
    //初始化公司信息
    this.getCompany(this.$route.query.companyId, "company");
    /*初始化轮播图*/
    this.selectPromotionList("zone_carousel", "carousel", "carouselList");
    this.getCategory(this.headCompanyId);
    /*初始化卡片*/
    this.getConfigs(203);

  },
  mounted() {

    let mobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );

    if (mobile != null) {
      this.isMobile = true
      console.log(this.isMobile)
    } else {
      this.isMobile = false
      console.log(this.isMobile)
    }

    // this.setHeadHeight();
    // window.addEventListener('resize', () => {
    //   // this.bannerHeight = this.$refs.bannerHeight[0].height;
    //   this.setHeadHeight();
    //   this.imgLoad();
    // }, false)
  },
  data() {
    return {
      entityBuyer: {},
      companyId: "",
      id: "",
      headCompanyId: "",
      headLogo: "",
      headId: "",
      carouselList: [],
      logoUrl: "",
      linkUrl: "",
      contactText: "",
      company: {},
      promotion: "",
      isBid: false,
      activeClass: 0,
      carousels: [],
      queryType: "all",
      bannerHeight: "",
      configForm: {},
      isMobile: false,
    };
  },
  methods: {
    toLink(type) {
      if (1 === type) {
        window.open('http://www.yongxinepm.com/');
      }
      if (2 === type) {
        window.open('http://www.yongxinepm.com/category/%e6%8b%9b%e6%a0%87%e4%bf%a1%e6%81%af/%e6%94%bf%e7%ad%96%e6%b3%95%e8%a7%84');
      }
    },
    login: function () {
      if (this.headId != null && this.headId != "") {
        window.open(this.memberThree + "/ebidding/#/login?redirect=%2Fmine%2Fmine");
      } else {
        window.open(this.member + "/member/login/executeLogin.htm");
      }
    },
    getConfigs: function (id) {
      var that = this;
      this.$http.get(this.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        var data = res.data.result;
        if (data) {
          that.configForm = data;
          var userNames = that.configForm.userName.split("/");
          var phones = that.configForm.phone.split("/");
          that.configForm['userNames'] = userNames;
          that.configForm['phones'] = phones;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      })
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      axios.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        axios.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
            if (data.data.result[i].companyId == this.headId) {
              data.data.result[i].bgUrl = JSON.parse(data.data.result[i].bgUrl);
              that.carousels.push(data.data.result[i]);
            }
          }
          if (this.headId == "") {
            let ca = {
              "id": "",
              "title": "",
              "imagesUrl": [{
                "path": "",
                "url": "https://old2.holdcg.com/group1/M00/02/E8/CgA7PmJWPgaAZPa3AAdhuxyoXvE150.png",
                "uid": "",
                "size": "66.15",
                "status": "success"
              }],
              "categoryId": "",
              "url": "",
              "linkText": "",
              "createdTime": "",
              "updateTime": null,
              "userId": "0",
              "companyId": "",
              "companyName": null,
              "isDeleted": 0,
              "isIssue": 0,
              "sortNo": 0,
              "groupId": null,
              "intervalId": null,
              "keyword": "",
              "description": "",
              "registeredCapital": "",
              "industry": "",
              "businessScope": "",
              "logoUrl": "\"\"",
              "bgUrl": [{
                "path": "",
                "url": "https://old2.holdcg.com/group1/M00/02/E8/CgA7PmJWPgaAZPa3AAdhuxyoXvE150.png",
                "uid": "",
                "size": "66.15",
                "status": "success"
              }],
              "contactText": "",
              "isRecommend": 0,
              "linkUrl": ""
            };
            that.carousels.push(ca);
          }

          that[entityListName] = data.data.result;
        })
      })
    },
    getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getCategory: function (id) {
      var that = this;
      let cId = id + "";
      if (cId != null && cId != '' && cId.length > 0) {
        that.$http.get(this.base + "/promotion/get.do", {
          params: {
            'id': cId
          }
        }).then((data) => {
          let dataRes = data.data.result;
          that.logoUrl = JSON.parse(dataRes.logoUrl)[0].url;
          that.linkUrl = dataRes.linkUrl;
          that.contactText = dataRes.contactText;
        })
      }
    },
    getCompany: function (promotionId, entityName) {
      var that = this;
      that.promotion = promotionId;
      that.$http.get(that.base + "/promotion/get.do", {
        params: {
          id: that.promotion
        }
      }).then((da) => {
        if (da.data.result.title.indexOf('涟源') != -1 || da.data.result.title.indexOf('涟钢') != -1 || da.data.result.title.indexOf('VAMA') != -1 || da.data.result.title.indexOf('安赛乐米塔尔') != -1) {
          that.isBid = true;
          that.activeClass = 1;
        }
        that[entityName] = da.data.result;
        that.$nextTick(() => {
          document.title = that.company.title;
        })
      });
    },
    getItem: function (index) {
      this.activeClass = index;
    },
    headActive: function (name) {
      return name == this.$route.name;
    },
    goShopping: function () {
      window.open("https://mall.holdcg.com");
    },
    setHeadHeight: function () {
      let head = document.getElementsByClassName("cms-header-content")[0].offsetHeight + 10;
    }
  }
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>
<style>

.head-active {
  font-weight: bold;
  border-bottom: 3px solid #275adc !important;
  color: #275adc !important;
}

@media (min-width: 768px) and (max-width: 992px) {
  .company-carousel .carousel-img {
    background-position: unset !important;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .company-carousel .el-carousel__container {
    height: 300px !important;
  }

  .company-carousel .carousel-img {
    object-fit: cover;
    object-position: left;
    height: 300px !important;
  }
}
</style>
<style scoped>

.menu_ul li:hover .one_a {
  font-weight: bold;
  color: #275adc !important;
  border-bottom: 3px solid #275adc !important;
}

.cms-header-content {
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
}

.home-head {
  background: #F4F4F4;
}

.menu_ul {
  border: none;
  margin-top: 10px;
  color: #6A6A6A;
  width: 600px;
  display: flex;
}

.categoryItem {
  cursor: pointer;
  flex: 1;
  text-align: center;
}

.cms-footer {
  width: 100%;
  height: 40px;
  background: #EFEFEF;
  display: flex;
  justify-content: center;
  color: #666666;
  font-size: 1.4rem;
  align-items: center;
}

.cms-header-content {
  background: #FFFFFF;
  width: 100%;
  height: 79px;
  margin: 0 auto;
  padding-top: 5px;
  margin-bottom: 20px;
}


.logo-image {
  width: 400px;
  height: 60px;
  margin: 2px 4px 1px 4px;
}

.menu_ul li {
  padding: 0px;
}

@media (min-width: 1200px) {
  .row1 {
    height: 300px;
    width: 1160px;
    margin-bottom: 20px;
  }

  .login {
    width: 120px;
  }

  .head-right {
    display: flex;
  }
}


.home-carousel {
  width: 908px;
  height: 100%;
}

.home-left {
  width: 233px;
  height: 100%;
  margin-left: 19px;
}

.menu_ul li .one_a {
  color: #6A6A6A;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-head {
    background: #F4F4F4;
  }

  /*.categoryItem {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }*/
  .menu_ul_new {
    width: 600px !important;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 83px;
    margin: 0 auto;
    padding-top: 10px;
    margin-bottom: 15px;
  }


  .logo-image {
    width: 334px;
    height: 63px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    height: 251px;
    width: 960px;
    margin-bottom: 15px;
  }

  .home-carousel {
    width: 756px;
    height: 100%;
  }

  .home-left {
    width: 195px;
    margin-left: 9px;
  }


  .company-content p[data-v-71f6a296] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 13;
    -webkit-box-orient: vertical;
  }

  .cms-head {
    width: 100% !important;
  }

  .login {
    margin-left: auto;
    width: 120px !important;
  }

  .head-right {
    display: flex;
  }
}

@media (min-width: 768px) and (max-width: 992px) {

  .menu_ul_new {
    width: 460px !important;
    margin-top: 0px !important;
  }

  /*.categoryItem {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }*/
  .home-head {
    background: #F4F4F4;
  }


  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
    margin-bottom: 10px;
  }


  .logo-image {
    width: 200px;
    height: 38px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    height: 300px;
    width: 720px;
    margin-bottom: 10px;
    position: relative;
  }

  .home-carousel {
    width: 100%;
    height: 100%;
  }

  .home-left {
    width: 245px;
    height: 100%;
    margin-left: 7px;
    position: absolute;
    z-index: 10000000;
    right: 10px;
    /*background: white;*/
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .cms-head {
    width: 100% !important;
  }

  .login {
    margin-left: auto;
    width: 120px !important;
  }

  .head-right {
    display: flex;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {
  .home-head {
    background: #F4F4F4;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 110px;
    margin: 0 auto;
    /*padding-top: 10px;*/
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }

  .row1 {
    /*height: 248px;*/
    width: 100%;
    margin-bottom: 10px;
    position: relative;
  }

  .home-carousel {
    width: 100%;
    height: 300px;
  }

  .home-left {
    width: 245px;
    height: 100%;
    margin-left: 7px;
    position: absolute;
    z-index: 10000000;
    right: 10px;
    /*background: white;*/
    padding: 10px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }


  .categoryItem {
    text-align: center;
    flex: 1;
    padding: 0px !important;
  }

  .menu_ul {
    padding: 0px;
    margin: 0px;
  }
}

@media screen and (max-width: 576px) {
  .home-head {
    width: 100%;
  }

  .logo-image {
    max-width: 60%;
  }

  .row1 {
    width: 100%;
  }

  .home-carousel {
    width: 100%;
    height: 100%;
  }

  .home-left {
    width: 100%;
    margin-left: 0px !important;
    padding: 10px;
  }

  .categoryItem {
    text-align: center;
    flex: 1;
    padding: 0px !important;
  }

  .menu_ul {
    padding: 0px;
    margin: 0px;
  }

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 120px;
    margin: 0 auto;
  }
}


.qy-head-active {
  font-weight: bold;
  border-bottom: 3px solid rgb(0, 104, 183) !important;
  color: rgb(0, 104, 183) !important;
}

.menu_ul li:hover .qy_one_a {
  color: rgb(0, 104, 183) !important;
  border-bottom: 3px solid rgb(0, 104, 183) !important;
}
</style>
