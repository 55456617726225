<template>
  <div>
    <div class="data-list">
      <router-link class="data-item" v-for="(item ,index) in operateGuide.children"
                   :key="'item4'+index" v-show="item.isIssue=='0'" :to="item.categoryUrl">{{ item.categoryTitle }}
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "operate-right",
  data() {
    return {
      operateGuide: {},
    }
  }, methods: {
    selectCategory: function (modelId, name) {
      var that = this;
      this.$http.get(this.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
      }).catch(function (error) {

      })
    },
  }, created() {
    this.selectCategory("operate_guide", "operateGuide");
  }
}
</script>

<style scoped lang="scss">
.data-list {
  display: flex;
  position: fixed;
  z-index: 10;
  right: 10px;
  top: 30%;
  width: 59px;
  flex-direction: column;
  gap: 5px;

  .data-item {
    cursor: pointer;
    background: #3B53B1;
    color: white;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }
}
</style>